import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';

const Login = ({ onSuccess, onClose }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false); // State to manage loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Set loading to true before request

        try {
            const response = await axios.post(
                'https://wd99p.com/jai/api/login',
                { username, password },
                { headers: { 'Content-Type': 'application/json' } }
            );

            const data = response.data;

            if (data.success) {
                onSuccess(username);
            } else {
                setError(data.message);
                setTimeout(() => {
                    setError('');
                }, 3000);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setError('Invalid username or password. Please try again.');
            setTimeout(() => {
                setError('');
            }, 3000);
        } finally {
            setIsLoading(false); // Set loading to false after request
        }
    };

    return (
        <div className="login-container">
            <button className="close-button" onClick={onClose}>&times;</button>
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Username</label>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Logging in...' : 'Login'}
                </button>
                {isLoading && <div className="spinner"></div>} {/* Loading spinner */}
                {error && <p className="error-message">{error}</p>}
            </form>
        </div>
    );
};

export default Login;
