import React from 'react';
import './ExampleCarouselImage.css';

const ExampleCarouselImage = ({ imageUrl, altText }) => {
  return (
    <div className="carousel-image-container">
      <img className="carousel-image" src={imageUrl} alt={altText} />
    </div>
  );
}

export default ExampleCarouselImage;
