import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Cart.css';

function Cart({ username, updateCartCount }) {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isUpdating, setIsUpdating] = useState(false); // Track update state
  const [updateItemId, setUpdateItemId] = useState(null); // Track which item is being updated
  const [updateQuantity, setUpdateQuantity] = useState(0); // Track new quantity for update

  useEffect(() => {
    fetchCartItems(); // Initial fetch
  }, [username]);

  useEffect(() => {
    // Recalculate total price whenever cart items change
    const calculateTotalPrice = () => {
      let total = 0;
      cartItems.forEach((item) => {
        total += item.price * item.quantity;
      });
      setTotalPrice(total);
    };

    calculateTotalPrice();
  }, [cartItems]);

  const fetchCartItems = async () => {
    try {
      setIsLoading(true); // Start loading
      const response = await axios.get(`https://wd99p.com/jai/api/cart?username=${encodeURIComponent(username)}`);
      const itemsWithPrice = await Promise.all(
        response.data.items.map(async (item) => {
          const productResponse = await axios.get(`https://wd99p.com/jai/api/product/${encodeURIComponent(item.title)}`);
          const product = productResponse.data;
          return {
            ...item,
            price: product.price, // Update item price from product data
            image: product.image, // Add image from product data
          };
        })
      );
      setCartItems(itemsWithPrice);
      setIsLoading(false); // Set loading state to false after successful fetch
    } catch (error) {
      console.error('Error fetching cart items:', error);
      setError('Failed to fetch cart items');
      setIsLoading(false); // Set loading state to false on error
    }
  };

  const handleRemoveItem = async (itemId) => {
    try {
      setIsLoading(true); // Start loading
      await axios.delete(`http://localhost:8000/api/cart/${itemId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          username: username,
        },
      });
      await fetchCartItems();
      updateCartCount(); // Update cart count after removal
    } catch (error) {
      console.error('Error removing item from cart:', error);
      setError('Failed to remove item from cart');
    } finally {
      setIsLoading(false); // Always set loading state to false after operation
    }
  };

  const handleUpdateItem = async (itemId) => {
    try {
      setIsUpdating(true);
      setUpdateItemId(itemId);
      const itemToUpdate = cartItems.find((item) => item.id === itemId);
      setUpdateQuantity(itemToUpdate.quantity);
    } catch (error) {
      console.error('Error updating item:', error);
      setError('Failed to update item');
    }
  };

  const confirmUpdateItem = async () => {
    try {
      setIsLoading(true); // Start loading
      const updatedItem = {
        id: updateItemId,
        quantity: updateQuantity,
      };
      await axios.put(`http://localhost:8000/api/cart/${updateItemId}`, updatedItem, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setIsUpdating(false);
      setUpdateItemId(null);
      setUpdateQuantity(0);
      await fetchCartItems();
      updateCartCount(); // Update cart count after update
    } catch (error) {
      console.error('Error updating item:', error);
      setError('Failed to update item');
    } finally {
      setIsLoading(false); // Always set loading state to false after operation
    }
  };

  const cancelUpdate = () => {
    setIsUpdating(false);
    setUpdateItemId(null);
    setUpdateQuantity(0);
  };

  const handleCheckout = () => {
    console.log('Checkout button clicked');
    // Implement checkout logic here
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError('');
    }, 3000);

    return () => clearTimeout(timeout);
  }, [error]);

  return (
    <div className="cart-container">
      <h1>Your Shopping Cart</h1>
      {isLoading && (
        <div className="loading-container">
          <p>Loading...</p>
          <div className="spinner"></div> {/* Loading spinner */}
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
      {cartItems.length === 0 && !isLoading && <p>Your cart is empty.</p>}
      {cartItems.length > 0 && (
        <div>
          {cartItems.map((item) => (
            <div key={item.id} className="cart-item">
              <div className="cart-item-image-container">
                <img src={item.image} alt={item.title} className="cart-item-image" />
              </div>
              <div className="cart-item-details">
                <h2>{item.title}</h2>
                <p>{item.description}</p>
                {isUpdating && updateItemId === item.id ? (
                  <div className="update-container">
                    <input
                      type="number"
                      value={updateQuantity}
                      onChange={(e) => setUpdateQuantity(e.target.value)}
                    />
                    <button onClick={confirmUpdateItem}>Update</button>
                    <button onClick={cancelUpdate} className="cancel-update-button">Cancel</button>
                  </div>
                ) : (
                  <div>
                    <p>Quantity: {item.quantity}</p>
                    <button onClick={() => handleUpdateItem(item.id)}>Update Quantity</button>
                  </div>
                )}
                <p>Price: {item.price ? `₱${Number(item.price).toFixed(2)}` : 'Price not available'}</p>
                <button onClick={() => handleRemoveItem(item.id)}>Remove</button>
              </div>
            </div>
          ))}
          <div className="total-price">
            <h2>Total Price: ₱{totalPrice.toFixed(2)}</h2>
          </div>
          <button onClick={handleCheckout}>Checkout</button>
        </div>
      )}
    </div>
  );
}

export default Cart;
