import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './ExampleCarouselImage';

function HomeSection() {
  return (
    <div className="home-section-container">
      <Carousel className='home-section'>
        <Carousel.Item interval={500}>
          <ExampleCarouselImage imageUrl="s.webp" className="d-block w-100" alt="First slide" />
          <Carousel.Caption className="caption-container h-100 d-flex flex-column justify-content-between">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="description">
                Welcome to TOO PURRFECT SHOP, your purr-fect destination for everything!
              </p>
              <div className="button-container d-flex justify-content-center">
                <button className="shop-now">Shop Now</button>
                <button className="get-started">Get Started</button>
              </div>
              <div className="d-flex justify-content-center align-items-end haay">
                <h3>Time spent with cats is never wasted.</h3>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <ExampleCarouselImage imageUrl="slider2-bg.webp" className="d-block w-100" alt="Second slide" />
          <Carousel.Caption className="caption-container h-100 d-flex flex-column justify-content-between">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="description">
                Welcome to TOO PURRFECT SHOP, your purr-fect destination for all things!
              </p>
              <div className="button-container d-flex justify-content-center">
                <button className="shop-now">Shop Now</button>
                <button className="get-started">Get Started</button>
              </div>
              <div className="d-flex justify-content-center align-items-end haay">
                <h3>Time spent with cats is never wasted.</h3>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <ExampleCarouselImage imageUrl="slider3-bg.webp" className="d-block w-100" alt="Third slide" />
          <Carousel.Caption className="caption-container h-100 d-flex flex-column justify-content-between">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="description">
                Welcome to TOO PURRFECT SHOP, your purr-fect destination for all things!
              </p>
              <div className="button-container d-flex justify-content-center">
                <button className="shop-now">Shop Now</button>
                <button className="get-started">Get Started</button>
              </div>
              <div className="d-flex justify-content-center align-items-end haay">
                <h3>The cat is nature's beauty.</h3>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="best-sellers-container">
        <h1 className="d-flex flex-column justify-content-center align-items-center hero">Best Sellers</h1>

        <div className="additional-images-container">
          <div className="product-container">
            <div className="product-box">
              <img src="homefood.webp" alt="Adult Dry Dog Food" />
              <div className="product-details">
                <h4>Adult Dry Dog Food</h4>
                <div>⭐️⭐️⭐️⭐️⭐️</div>
              </div>
            </div>
          </div>

          <div className="product-container">
            <div className="product-box">
              <img src="homefood1.webp" alt="Pup Booster" />
              <div className="product-details">
                <h4>Pup Booster</h4>
                <div>⭐️⭐️⭐️⭐️⭐️</div>
              </div>
            </div>
          </div>

          <div className="product-container">
            <div className="product-box">
              <img src="homefood3.webp" alt="Vegetarian Dog Food" />
              <div className="product-details">
                <h4>Vegetarian Dog Food</h4>
                <div>⭐️⭐️⭐️⭐️⭐️</div>
              </div>
            </div>
          </div>

          <div className="product-container">
            <div className="product-box">
              <img src="food4.webp" alt="Tuna In Jelly" />
              <div className="product-details">
                <h4>Tuna In Jelly</h4>
                <div>⭐️⭐️⭐️⭐️⭐️</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sale">
        <div className="image-wrapper">
          <img src="grid04.png" alt="Hot Deals Offer" />
        </div>
        <div className="dt-sc-deal-banner-content text-center dt-sc-heading">
          <h1 className="wow slideToggle dt-sc-main-title-animated">Hot Deals Offer!</h1>
          <br />
          <h2>SHOP NOW!</h2>
        </div>
      </div>

      <h1 className="d-flex flex-column justify-content-center align-items-center hero">Follow Us On Instagram!</h1>
      <div className="insta-images-container">
        <div className="instaimg1">
          <img src="Insta-1_360x.webp" alt="Instagram Image 1" />
        </div>
        <div className="instaimg2">
          <img src="Insta-2_360x.webp" alt="Instagram Image 2" />
        </div>
        <div className="instaimg3">
          <img src="Insta-3_360x.jpg" alt="Instagram Image 3" />
        </div>
        <div className="instaimg4">
          <img src="Insta-4_360x.webp" alt="Instagram Image 4" />
        </div>
        <div className="instaimg5">
          <img src="Insta-5_360x.jpg" alt="Instagram Image 5" />
        </div>
      </div>

      <div>
        <h1 className="d-flex flex-column justify-content-center align-items-center best">Our Best Partners</h1>
        <div className="sub-images-container">
          <div className="sub1">
            <img src="sub1.png" alt="Partner Image 1" />
          </div>
          <div className="sub2">
            <img src="sub2.png" alt="Partner Image 2" />
          </div>
          <div className="sub3">
            <img src="sub3.png" alt="Partner Image 3" />
          </div>
          <div className="sub4">
            <img src="sub4.png" alt="Partner Image 4" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSection;
