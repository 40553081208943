import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ExampleCarouselImage.css';

function ServicesSection({ username }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://jaipp5.wd99p.com/api/products');
        setAllProducts(response.data);
        setSearchResults(response.data);
        setIsLoadingProducts(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  // Filter products based on search term
  useEffect(() => {
    const filteredResults = allProducts.filter(product =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredResults);
  }, [searchTerm, allProducts]);

  useEffect(() => {
    if (selectedItem) {
      setTotalPrice(selectedItem.price * quantity);
    }
  }, [selectedItem, quantity]);

  const handleAddToCart = (item) => {
    if (!username) {
      setError('Please login to add items to cart');
      setTimeout(() => {
        setError('');
      }, 3000);
      return;
    }
    setSelectedItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setQuantity(1);
    setError('');
  };

  const handleModalClick = (event) => {
    if (event.target.classList.contains('modal')) {
      closeModal();
    }
  };

  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleAddToCartModal = () => {
    if (!username) {
      setError('Please login to add items to cart');
      setTimeout(() => {
        setError('');
      }, 3000);
      return;
    }
    const newItem = {
      username: username,
      title: selectedItem.title,
      quantity: quantity,
      total_price: totalPrice.toFixed(2),
    };

    setIsAddingToCart(true);

    axios.post('https://jaipp5.wd99p.com/api/add-to-cart', newItem, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        console.log('Item added/updated in cart:', response.data);
        setMessage('Item added to cart successfully!');
        setIsAddingToCart(false);
        closeModal();
        setTimeout(() => {
          setMessage('');
        }, 3000);
        setTimeout(() => {
          window.location.reload();
        }, 3500);
      })
      .catch(error => {
        console.error('Error adding/updating item in cart:', error);
        setIsAddingToCart(false);
        if (error.response && error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        } else {
          setError('Failed to add item to cart');
        }
      });
  };

  return (
    <div>
      <h1 className="d-flex flex-column justify-content-center align-items-center serv">SHOP</h1>
      {isLoadingProducts ? (
        <div className="loading-container">
          <p>Loading products...</p>
          <div className="spinner"></div> {/* Loading spinner */}
        </div>
      ) : (
        <div>
          <div className="photo-grid">
            <div className="grid-item">
              <img src="serv1.1.png" alt="Service 1" />
              <p>Made with Love</p>
            </div>
            <div className="grid-item">
              <img src="serv2.1.png" alt="Service 2" />
              <p>Purely Organic</p>
            </div>
            <div className="grid-item">
              <img src="serv3.1.png" alt="Service 3" />
              <p>Best Quality Products</p>
            </div>
          </div>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
          <div className="image-container">
            {showModal && (
              <div className="modal-wrapper">
                <div className="modal" onClick={handleModalClick}>
                  <div className="modal-content">
                    <span className="close" onClick={closeModal}>&times;</span>
                    {selectedItem && (
                      <div>
                        <h2>{selectedItem.title}</h2>
                        <img src={selectedItem.image} alt={selectedItem.title} className="centered-image" />
                        <p>{selectedItem.description}</p>
                        <div className="centered-container">
                          <div className="quantity-container">
                            <label>Quantity:</label>
                            <button onClick={handleDecreaseQuantity}>-</button>
                            <input
                              type="text"
                              value={quantity}
                              disabled
                            />
                            <button onClick={handleIncreaseQuantity}>+</button>
                          </div>
                          <p>Total Price: ₱{totalPrice.toFixed(2)}</p>
                          <button onClick={handleAddToCartModal} style={{ backgroundColor: '#e38ebe', color: 'white' }}>Buy Now</button>
                          {error && <p>{error}</p>}
                          {isAddingToCart && <p>Adding to cart...</p>}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {searchResults.length > 0 ? (
              searchResults.map((item) => (
                <div className="image-item" key={item.id}>
                  <img src={item.image} alt={item.title} className="image" />
                  <div className="image-description">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <button onClick={() => handleAddToCart(item)}>Add to Cart</button>
                    {error && <p>{error}</p>}
                  </div>
                </div>
              ))
            ) : (
              <p>No products available matching your search.</p>
            )}
          </div>
          <div>
            <h1 className="d-flex flex-column justify-content-center align-items-center abtbest">Our Best Partners</h1>
            <div className="sub-images-container">
              <div className="sub1">
                <img src="sub1.png" alt="Additional Image 1" /> </div>
              <div className="sub2">
                <img src="sub2.png" alt="Additional Image 1" /> </div>
              <div className="sub3">
                <img src="sub3.png" alt="Additional Image 1" /> </div>
              <div className="sub4">
                <img src="sub4.png" alt="Additional Image 1" /> </div>
            </div>
          </div>
          {message && (
            <div className="message-container">
              <p>{message}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ServicesSection;
