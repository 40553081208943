import React, { useRef, useState } from 'react';
import './ExampleCarouselImage.css';

const AboutUsSection = () => {
  const abt1Ref = useRef(null);
  const [abt1Height, setAbt1Height] = useState(0);

  const handleAbt1Load = () => {
    const height = abt1Ref.current.offsetHeight;
    setAbt1Height(height);
  };

  return (
    <div>
      <div className="about-us">  
        <div className="about-heading">
          <div className='test2'>
            <h1 className="fall-from-top">ABOUT US</h1>
          </div>
          <div className="melon">
            <h2>Discover our products at Too Purrfect Shop. Browse our shop!</h2>
          </div>
        </div>
        <p className="d-flex flex-column justify-content-center align-items-center hero">Food Features</p>
        <div className="abtfeat">
          <div className= "abtfeat-container">
              <img src="about5.jpg" alt="Additional Image 4" />
              <div className="abtfeat-description ">
            <h4 className= "abtdesc">Human-Grade Ingredients</h4>
              <p>Food components that meet the same quality and safety standards required for human consumption. 
                Using human-grade ingredients in pet food is believed to provide pets with better-quality nutrition, similar to what humans would consume themselves.</p>
            <h4 className="abtdesc">Easy To Digest</h4>
              <p>Foods typically contain nutrients in forms that the body can readily process, reducing the strain on the digestive organs. 
                Easy-to-digest foods often include lean proteins, cooked vegetables, and easily digestible carbohydrates, which can help promote digestive comfort and nutrient absorption.</p>
            <h4 className="abtdesc">Delicious Taste</h4>
              <p>Food that is highly enjoyable and satisfying to eat. 
                Delicious-tasting food often stimulates the taste buds, triggers positive emotional responses, and leaves a lingering desire for more.
                </p>
            </div>
          </div>
        </div>

        <div className="about-content">
          <div className="about-text">
            <div className='test1'>
              <p>Who We Are?</p>
            </div>
            <p>Welcome to Too Purrfect Shop, your trusted source for premium cat foods and supplies. Founded in 2024,
              we are dedicated to providing the highest quality nutrition and care for your feline companions. At Too Purrfect Shop, 
              our mission is simple: to enrich the lives of cats and their owners by offering a wide range of nutritious foods, delicious treats, comfortable bedding, stimulating toys, and essential accessories.
              We believe that every cat deserves the best, and we're here to ensure they receive it</p>
          </div>
          <div className="about-text">
            <div className="test3">
              <p>Why Choose Us?</p>
            </div>
            <ul>
              <li>Quality Products: We carefully select and offer only the finest quality cat foods, treats, and supplies from trusted brands.</li>
              <li>Health and Wellness: We prioritize the health and wellness of cats, providing products formulated to support their unique nutritional needs.</li>
              <li>Expert Advice: Our knowledgeable team is passionate about cats and is here to provide expert advice and guidance to help you make the best choices for your furry friend.</li>
              <li>Convenience: With our user-friendly online store and fast, reliable shipping, shopping for your cat has never been easier.</li>
              <li>Customer Satisfaction: We are committed to providing exceptional customer service and ensuring your complete satisfaction with every purchase.</li>
            </ul>
          </div>
        </div>
       
      </div>
      <div>
      <h1 className="d-flex flex-column justify-content-center align-items-center abtbest">Our Best Partners</h1>
      <div className="sub-images-container">
              <div class="sub1">
                  <img src="sub1.png" alt="Additional Image 1" /> </div>
              <div class="sub2">
                  <img src="sub2.png" alt="Additional Image 1" /> </div>
              <div class="sub3">
                  <img src="sub3.png" alt="Additional Image 1" /> </div>
              <div class="sub4">
                  <img src="sub4.png" alt="Additional Image 1" /> </div>    
             </div>
      </div>
    </div>
  );
}

export default AboutUsSection;
