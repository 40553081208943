import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Login from './components/Login';

function App() {
  return (
    <div>
      <Navbar />
      <Footer /> 
    </div>
  );
}

export default App;
