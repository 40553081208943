import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import Home from './Home';
import AboutUs from './AboutUs';
import Services from './Services';
import Contact from './Contact';
import Login from './Login';
import Registration from './Registration';
import Cart from './Cart'; 
import axios from 'axios';
import '../App.css';

function Navbar() {
  const [showLogin, setShowLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [cartCount, setCartCount] = useState(0);

  const handleLogin = (username) => {
    setIsLoggedIn(true);
    setShowLogin(false);
    setUsername(username);
    sessionStorage.setItem('username', username);
    fetchCartCount();
    window.location.reload();
  };

  const handleLogout = async () => {
    try {
      await axios.post(
        'https://wd99p.com/jai/pp5/crud/updateUserStatus.php',
        { username },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setIsLoggedIn(false);
      setUsername('');
      setCartCount(0);
      sessionStorage.removeItem('username');
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const fetchCartCount = async () => {
    try {
      const response = await axios.post(
        'https://wd99p.com/jai/pp5/crud/fetchCartCount.php',
        { username },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const count = response.data.count;
      setCartCount(count);
      console.log('Cart count fetched:', count);
    } catch (error) {
      console.error('Error fetching cart count:', error);
    }
  };

  useEffect(() => {
    const username = sessionStorage.getItem('username');
    if (username) {
      setUsername(username);
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchCartCount();
    } else {
      setCartCount(0);
    }
  }, [isLoggedIn]);

  const updateCartCount = async () => {
    try {
      const response = await axios.post(
        'https://wd99p.com/jai/pp5/crud/fetchCartCount.php',
        { username },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const count = response.data.count;
      setCartCount(count);
      console.log('Cart count updated:', count);
    } catch (error) {
      console.error('Error updating cart count:', error);
    }
  };

  return (
    <Router>
      <div className="nav-container">
        <BootstrapNavbar className="test bg-light">
          <Container fluid>
            <BootstrapNavbar.Brand className="me-auto">
              <img
                src="purrfect.png"
                width="80"
                height="70"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </BootstrapNavbar.Brand>
            <Nav className="me-auto text-center">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/about">About Us</Nav.Link>
              <Nav.Link as={Link} to="/services">Shop</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
              {isLoggedIn ? (
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              ) : (
                <>
                  <Nav.Link onClick={() => setShowLogin(!showLogin)}>Login</Nav.Link>
                  <Nav.Link as={Link} to="/register">Register</Nav.Link>
                </>
              )}
            </Nav>
            {isLoggedIn && <div>{username}</div>}
            {isLoggedIn && (
              <div className="cart-image" style={{ marginRight: '40px' }}>
                <Link to="/cart">
                  <img
                    src="cart.png"
                    className="d-inline-block align-top"
                    alt="Cart"
                  />
                  <span className="cart-count">{cartCount}</span>
                </Link>
              </div>
            )}
          </Container>
        </BootstrapNavbar>
        {showLogin && (
          <div className="login-panel">
            <Login onSuccess={handleLogin} onClose={() => setShowLogin(false)} />
          </div>
        )}
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services username={username} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cart" element={<Cart username={username} updateCartCount={updateCartCount} />} />
        <Route path="/register" element={<Registration />} />
      </Routes>
    </Router>
  );
}

export default Navbar;
