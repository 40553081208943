import React, { useState } from 'react';
import axios from 'axios';
import './Contact.css'; // Import the Contact.css file for styling

function Contact() {
  const [formStatus, setFormStatus] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [showSpinner, setShowSpinner] = useState(false); // State for showing spinner

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowSpinner(true); // Show spinner on form submit
    try {
      const response = await axios.post('https://wd99p.com/jai/api/contact-service', formData);
      console.log(response.data);
      setFormStatus('success');

      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });

      setTimeout(() => {
        setFormStatus(null);
        setShowSpinner(false); // Hide spinner after form submission feedback
      }, 3000); // Show form status message for 3 seconds
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormStatus('error');
      setShowSpinner(false); // Hide spinner on error
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-info">
        <h1 className="contact-title">CONTACT US</h1>
        <div className="info-item">
          <i className="bi bi-geo-alt"></i>
          <div className="info-text">
            <h4>Location:</h4>
            <p>San Jose del Monte Bulacan</p>
          </div>
        </div>
        <div className="info-item">
          <i className="bi bi-envelope"></i>
          <div className="info-text">
            <h4>Email:</h4>
            <p>toopurrfectshop@gmail.com</p>
          </div>
        </div>
        <div className="info-item">
          <i className="bi bi-phone"></i>
          <div className="info-text">
            <h4>Call:</h4>
            <p>+639-1234-5678</p>
          </div>
        </div>
      </div>
      <div className="contact-form">
        {showSpinner && (
          <div className="spinner-container">
            {/* Centered spinner */}
            <div className="spinner"></div>
          </div>
        )}
        {formStatus === 'success' && (
          <div className="alert alert-success" role="alert">
            Your message has been sent successfully. Thank you!
          </div>
        )}
        {formStatus === 'error' && (
          <div className="alert alert-danger" role="alert">
            Sorry, there was an error sending your message. Please try again later.
          </div>
        )}
        <form onSubmit={handleSubmit} className="php-email-form">
          <div className="form-group">
            <input type="text" name="name" className="form-control" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <input type="email" name="email" className="form-control" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <input type="text" name="subject" className="form-control" placeholder="Subject" value={formData.subject} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <textarea name="message" className="form-control" rows="5" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
          </div>
          <div className="text-center">
            <button className="btn custom-button">Send Message</button>
          </div>
        </form>
      </div>
      <div className="best-partners">
        <h1 className="partners-title">Our Best Partners</h1>
        <div className="sub-images-container">
          <div className="sub1">
            <img src="sub1.png" alt="Additional Image 1" />
          </div>
          <div className="sub2">
            <img src="sub2.png" alt="Additional Image 1" />
          </div>
          <div className="sub3">
            <img src="sub3.png" alt="Additional Image 1" />
          </div>
          <div className="sub4">
            <img src="sub4.png" alt="Additional Image 1" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
