import React from 'react';

const Footer = () => {
  return (
    <footer id="footer">
    <div class="container">
      <div class="copyright">
        © Copyright<strong><span>TooPurrfectShop2024</span></strong>. All Rights Reserved
      </div>
    </div>
  </footer>
  );
}

export default Footer;
